import './App.css';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';


import Index from './pages';
import Form from './pages/form';
import Thankyou from './pages/thankyou';
import Dnaform from './pages/Dnaform';
import Thankyou2 from './pages/thankyou2';
import Praform from './pages/praform';
import Thankyou3 from './pages/thankyou3';

const Nav = () => {
  const navigate = useNavigate()
  return (<>
    <nav>
      <div className='logo'></div>
      <div ><button className='button' onClick={() => navigate("/form")}>Register your kit</button></div>
    </nav>
  </>)
}

function App() {

  return (
    <div className="App">

      <BrowserRouter>


        <Nav />
        <Routes>

          <Route path='/' element={<Index />} />
          <Route path='/form' element={<Form />} />
          <Route path='/thankyou' element={<Thankyou />} />
          <Route path='/thank-you' element={<Thankyou2 />} />
          <Route path='/thank-youu' element={<Thankyou3 />} />
          <Route path='/dnaform' element={<Dnaform />} />
          <Route path='/parasitologyform' element={<Praform />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
