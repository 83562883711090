import { message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Praform() {

    useEffect(() => {
        document.title = 'GutHealthlab DNA Form';
      }, []);

    const navigate = useNavigate()

    const [formData, setFormData] = useState({

        name: '',
        date: '',
        barcode: '',
        gender: '',
        email: ''
    });


    // Handle input changes
    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const submintdata = async (e) => {
        e.preventDefault()
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("name", formData.name);
        urlencoded.append("date", formData.date);
        urlencoded.append("email", formData.email);
        urlencoded.append("gender", formData.gender);
        urlencoded.append("barcode", formData.barcode);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/praformformdata", requestOptions)
            .then((response) => response.text())
            .then((result) => message.success(result))
            .catch((error) => console.error(error));


        navigate("/thank-youu")
    }

    return (
        <div className='mainformdiv' style={{ flexDirection: 'column' }}>

            <h1 style={{ color: '#0B233A' }}>Parasitology Test Form</h1>
            <form onSubmit={submintdata} >
                <div className='flexxx' style={{ display: 'flex', width: '100%', justifyContent: "space-between" }}>
                    <div className='widhtttt'>
                        <label>Your Name <span style={{ color: 'red' }}>*</span></label>
                        <input
                            type='text'
                            name='name'
                            required
                            value={formData.name}
                            onChange={handleInputChange2}
                        />
                    </div>
                    <div className='widhtttt'>
                        <label>Your E-mail <span style={{ color: 'red' }}>*</span></label>
                        <input
                            type='text'
                            name='email'
                            required
                            value={formData.email}
                            onChange={handleInputChange2}
                        />
                    </div>


                </div>


                <div className='flexxx' style={{ display: 'flex', width: '100%', justifyContent: "space-between" }}>
                    <div className='widhtttt'>
                        <label>Sample date <span style={{ color: 'red' }}>*</span></label>
                        <input
                            type='date'
                            name='date'
                            required
                            value={formData.date}
                            onChange={handleInputChange2}
                        />
                    </div>
                    <div className='widhtttt'>
                        <label> Kitcode Number  <span style={{ color: 'red' }}>*</span></label>
                        <input
                            type='text'
                            name='barcode'
                            required
                            value={formData.barcode}
                            onChange={handleInputChange2}
                        />
                    </div>


                </div>


                <div >
                    <label> Gender  <span style={{ color: 'red' }}>*</span></label>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '50px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: 'fit-content', gap: "10px", alignItems: 'center' }}>
                            <input
                                type='radio'
                                name='gender'
                                required
                                style={{ width: '30px', height: '30px' }}
                                value="Male"
                                onChange={handleInputChange2}
                            /><label>Male</label></div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: "10px", alignItems: 'center' }}>
                            <input
                                type='radio'
                                name='gender'
                                required
                                style={{ width: '30px', height: '30px' }}
                                value="Female"
                                onChange={handleInputChange2}
                            /><label>Female</label></div>
                    </div>
                </div>

                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: "10px" }}>
                    <input
                        type='checkbox'
                        // name='barcode'
                        required
                        style={{ width: '30px', height: '30px' }}
                        value={formData.namel}
                    // onChange={handleInputChange2}
                    />
                    <label> I give consent for my sample to be analysed, all data will be completely anonymous and stored securely.</label>

                </div>

                <div>
                    <button className='button2' type='submit'>SEND</button>
                </div>
            </form>
        </div>
    )
}

export default Praform
